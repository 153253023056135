import React, { Component } from "react";
import {
  Backdrop,
  CircularProgress,
  TextField,
  Slider,
  Tooltip,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import {
  DoubleArrow,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  RestartAlt,
  Send,
} from "@mui/icons-material";
import { useStyles } from "../../custom_libraries/customStyles";
import { getIdToken } from "../../custom_libraries/auth";
import urls from "../../custom_libraries/urls";
import { post } from "../../custom_libraries/serverRequests";
import firebaseConfig from "../../custom_libraries/firebaseConfig";
import { firebaseErrors } from "../../custom_libraries/validation";
import { Fab, Button } from "@material-ui/core";

const marks = [
  {
    value: 50,
    label: 50,
  },
  {
    value: 75,
    label: 75,
  },
  {
    value: 100,
    label: 100,
  },
];

const initialState = {
  link: "",
  text: "",
  ext_text_summary: "",
  text_summary: "",
  summ_length: 75,

  dialog_input_text: false,
  dialog_summ_length: false,
  open_progress_bar: false,
};

class SummaryGeneration extends Component {
  constructor(props) {
    super(props);
    this.page = React.createRef();
    this.modify_summary = React.createRef();
  }

  state = { ...initialState };

  componentDidMount() {
    this.validateSignedIn();
  }

  // componentWillUnmount() {
  //   // fix Warning: Can't perform a React state update on an unmounted component
  //   this.setState = (state, callback) => {
  //     return;
  //   };
  // }

  validateSignedIn = async () => {};

  takeSummaryLength = () => {
    let link = this.state.link.trim();
    let text = this.state.text.trim();
    if (link.length < 15 && text.length < 25) {
      alert("The minimum length of Link should be 15 or Text should be 25");
      return;
    }
    this.setState({ dialog_summ_length: true });
  };

  getSummary = async () => {
    this.startLoading();
    let headers = {
      "content-type": "application/json",
      "id-token": await getIdToken(),
    };
    let post_data = JSON.stringify({
      link: this.state.link.trim(),
      text: this.state.text.trim(),
      summary_length: this.state.summ_length,
    });
    let response = await post(urls.GENERATE_SUMMARY, headers, post_data);
    if (response !== false) {
      // console.log('data', response)
      if (!response["success"]) {
        alert(response["message"]);
        this.stopLoading();
        return;
      }
      this.setState({
        ext_text_summary: JSON.parse(JSON.stringify(response["text_summary"])),
        text: response["text"],
        text_summary: response["text_summary"],
      });
      this.closeDialog();
    } else {
      alert("Something went wrong. Please try again !!!");
    }
    this.stopLoading();
  };

  submitSummary = async () => {
    let modified_summary = this.state.text_summary.trim();
    if (modified_summary.length < 10) {
      alert("The minimum length of Text Summary should be 10");
      return;
    }
    this.startLoading();
    let data = {
      submit_time: new Date(),
      link: this.state.link.trim(),
      text: this.state.text.trim(),
      extracted_summary: this.state.ext_text_summary,
      modified_summary: modified_summary,
      word_length: this.state.summ_length,
    };
    const doc_id = new Date().toISOString().replaceAll(/[^0-9]+/g, "");
    const document = firebaseConfig
      .firestore()
      .collection("the_hindu")
      .doc(doc_id);
    await document
      .set(data)
      .then(() => {
        alert("Summary submited successfully !!!");
        this.handleReset();
      })
      .catch((error) => {
        firebaseErrors(error);
      });
    this.stopLoading();
  };

  closeDialog = () => {
    this.setState({
      dialog_input_text: false,
      dialog_summ_length: false,
    });
  };

  startLoading = () => {
    this.setState({ open_progress_bar: true });
  };

  stopLoading = () => {
    this.setState({ open_progress_bar: false });
  };

  handleReset = () => {
    this.setState(initialState);
  };

  render() {
    return (
      <div className="scroll_hidden ps-1 pe-1" ref={this.page}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 text-center">
              <TextField
                className="w-100 mt-4"
                variant="outlined"
                label="Enter Link"
                placeholder="https://<source_link...>"
                value={this.state.link}
                onChange={(evt) => this.setState({ link: evt.target.value })}
              />
              <Button
                size="large"
                color="primary"
                variant="outlined"
                style={{ borderRadius: "10px", pointerEvents: "none" }}
                className="mt-3 mb-2"
              >
                <KeyboardDoubleArrowUp className="me-2" />
                <b>OR</b>
                <KeyboardDoubleArrowDown className="ms-2" />
              </Button>
              <TextField
                className="w-100 mt-2"
                variant="outlined"
                label="Enter Text"
                placeholder="Enter Text"
                multiline
                minRows={10}
                maxRows={10}
                value={this.state.text}
                onChange={(evt) =>
                  this.setState({ text: evt.target.value, link: "" })
                }
                InputProps={{
                  classes: { input: this.props.classes.newsTextField },
                }}
              />
            </div>
            <div className="col-md-6">
              <div className="text-end">
                <Button
                  size="large"
                  color="inherit"
                  variant="outlined"
                  className="mt-4 me-3"
                  style={{ borderRadius: "10px" }}
                  onClick={this.handleReset}
                >
                  <b>Reset All</b>
                  <RestartAlt className="ms-2" />
                </Button>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  className="mt-4"
                  style={{ borderRadius: "10px" }}
                  onClick={this.submitSummary}
                >
                  <b>Submit</b>
                  <Send className="ms-2" />
                </Button>
              </div>
              <small>
                <b>Summary Length</b>
              </small>
              <div className="ps-4 pe-4">
                <Slider
                  value={this.state.summ_length}
                  step={1}
                  max={100}
                  min={50}
                  marks={marks}
                  onChange={(e, val, activeThumb) =>
                    this.setState({ summ_length: val })
                  }
                  valueLabelDisplay="on"
                  disableSwap
                />
              </div>
              <TextField
                variant="outlined"
                label="Text Summary"
                multiline
                minRows={10}
                maxRows={10}
                className="w-100 mt-2"
                inputProps={{ style: { textAlign: "left" } }}
                value={this.state.text_summary}
                onChange={(evt) =>
                  this.setState({ text_summary: evt.target.value })
                }
                disabled={this.state.button_disabled}
                InputProps={{
                  classes: { input: this.props.classes.newsTextField },
                }}
              />
            </div>
          </div>
        </div>
        <Tooltip title="Extract Summary" placement="top" arrow>
          <Fab
            size="large"
            color="secondary"
            onClick={this.getSummary}
            className="centered"
          >
            <DoubleArrow />
          </Fab>
        </Tooltip>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.open_progress_bar}
        >
          <CircularProgress size={80} color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

export default withStyles(useStyles)(SummaryGeneration);
