import React, { Component } from "react";
import {
  FormControlLabel,
  Checkbox,
  Fab,
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { useStyles } from "../../custom_libraries/customStyles2";

class LanguageTranslation extends Component {
  constructor(props) {
    super(props);
    this.file_chooser = React.createRef();
  }

  state = {
    company_name_list: [],
    company_name: "",

    hidden_is_vocab: true,
    is_vocab: false,

    input_file: "",
    output_file: "",

    open_progress_bar: false,
    spinner_hidden: true,
    button_disabled: false,
  };

  componentDidMount() {
    this.getCompanyName();
  }

  timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  handleCompanyName = (evt) => {
    let company_name = evt.target.value;
    if (
      company_name !== null ||
      company_name !== undefined ||
      company_name !== ""
    ) {
      this.setState({ company_name: company_name });
      this.getOutputFiles(company_name);
      this.setState({ is_vocab: false });
      this.setState({ input_file: "" });
      this.setState({ output_file: "" });
    }
  };

  handleVocabCheckbox = async (evt) => {
    if (
      this.state.company_name === "" ||
      this.state.company_name === null ||
      this.state.company_name === undefined
    ) {
      alert("Please select company name !");
      return;
    }
    let is_vocab = evt.target.checked;
    this.setState({ is_vocab: is_vocab });

    this.startProgressBar();
    this.setState({ output_file: "" });
    // Delay of 5 Seconds
    await this.timeout(5000);

    if (is_vocab) {
      this.setState({
        output_file:
          "static/language_translation/" +
          this.state.company_name +
          "_vocab.pdf#toolbar=0",
      });
    } else {
      this.setState({
        output_file:
          "static/language_translation/" +
          this.state.company_name +
          "_novocab.pdf#toolbar=0",
      });
    }
    this.stopProgressBar();
  };

  getCompanyName = async () => {
    this.startProgressBar();
    let company_name_list = [
      "ABA Bank",
      "LK PT PRIMARINDO ASIA INFRASTRUCTURE",
      "PT LANGGENG MAKMUR INDUSTRI Tbk",
      "PT Victoria Insurance",
      "BANK OF HANGZHOU",
    ];
    this.setState({ company_name_list: company_name_list });
    this.stopProgressBar();
  };

  startProgressBar = () => {
    this.setState({ open_progress_bar: true });
    this.setState({ button_disabled: true });
    this.setState({ spinner_hidden: false });
  };

  stopProgressBar = () => {
    this.setState({ open_progress_bar: false });
    this.setState({ button_disabled: false });
    this.setState({ spinner_hidden: true });
  };

  getOutputFiles = async (company_name) => {
    this.startProgressBar();
    // Delay of 8 Seconds
    await this.timeout(8000);
    this.setState({
      input_file:
        "static/language_translation/" + company_name + ".pdf#toolbar=0",
    });
    this.setState({
      output_file:
        "static/language_translation/" +
        company_name +
        "_novocab.pdf#toolbar=0",
    });
    this.setState({ hidden_is_vocab: false });
    this.stopProgressBar();
  };

  render() {
    return (
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-md-6">
            <FormControl
              size="small"
              variant="outlined"
              className="w-100 mt-2"
              disabled={this.state.button_disabled}
            >
              <InputLabel>Company Name</InputLabel>
              <Select
                value={this.state.company_name}
                onChange={this.handleCompanyName}
                label="Company Name"
              >
                {this.state.company_name_list.map((k, i) => (
                  <MenuItem key={k} value={this.state.company_name_list[i]}>
                    {this.state.company_name_list[i]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <iframe
              className="w-100 border mt-2 scroll"
              style={{ height: "calc(100vh - 10rem)" }}
              src={this.state.input_file}
              key={this.state.input_file}
            />
          </div>
          <div className="col-md-6">
            <div className="mt-1 ml-2">
              <FormControlLabel
                disabled={this.state.button_disabled}
                hidden={this.state.hidden_is_vocab}
                control={
                  <Checkbox
                    checked={this.state.is_vocab}
                    onChange={this.handleVocabCheckbox}
                    color="primary"
                  />
                }
                label="Use contextual vocabulary"
                className="m-0"
              />
              <Fab
                size="small"
                className="small_button text-white mt-2 mr-2"
                style={{ visibility: "hidden" }}
              ></Fab>
            </div>
            <iframe
              className="w-100 border mt-1 scroll"
              style={{ height: "calc(100vh - 10rem)" }}
              src={this.state.output_file}
              key={this.state.output_file}
            />
          </div>
        </div>
        <Backdrop
          className={this.props.classes.backdrop}
          open={this.state.open_progress_bar}
        >
          <CircularProgress size={80} color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

export default withStyles(useStyles)(LanguageTranslation);
