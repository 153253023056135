import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAY7f4ElwBu01Pe6wzWOUSxb12i97lOtpM",
  authDomain: "the-walnut-ai.firebaseapp.com",
  projectId: "the-walnut-ai",
  storageBucket: "the-walnut-ai.appspot.com",
  messagingSenderId: "993344344807",
  appId: "1:993344344807:web:0a4650be4ae607cd074842",
  measurementId: "G-MHTRWZP0KC",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
