import firebaseConfig from "./firebaseConfig";
import gv from "./globalVariables";
import { firebaseErrors } from "./validation";

export async function isAuthenticated() {
  if (!window.navigator.onLine) {
    alert("Check your internet connection and try again");
    return false;
  }
  let path = window.location.pathname;
  const signed_in_paths = ["/sign_in", "/sign_up"];
  try {
    await new Promise((resolve, reject) => {
      firebaseConfig.auth().onAuthStateChanged(
        (user) => {
          if (user) {
            // User is signed in.
            resolve(user);
            if (signed_in_paths.includes(path) && user.emailVerified) {
              window.location.replace("/");
            } else if (!user.email && path !== "/sign_up") {
              window.location.replace("/sign_up");
            }
          } else {
            // No user is signed in.
            reject("no user logged in");
            if (path !== "/sign_in" && path !== "/sign_up") {
              signOut();
            }
          }
        },
        // Prevent console error
        (error) => {
          reject(error);
        }
      );
    });
    // showLoading(false)
    return true;
  } catch (error) {
    // showLoading(false)
    return false;
  }
}

export async function getIdToken() {
  let id_token = await firebaseConfig.auth().currentUser.getIdToken();
  return id_token;
}

export async function signOut() {
  await firebaseConfig
    .auth()
    .signOut()
    .then(() => {
      window.location.replace("/sign_in");
    })
    .catch((error) => {
      firebaseErrors(error);
    });
}
