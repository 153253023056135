import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Backdrop,
  CircularProgress,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import sign_data from "../../jsons/sign_verification_data.json";
import "../Home.css";

const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

const initialState = {
  file: [],
  file_src: "",
  file_name: "",
  doc_type: "---",
  acc_no: "---",
  extracted_sign: "",
  auth_sign: "",
  result: "",
  button_disabled: true,
  open_progress_bar: false,
  hidden_table: false,
};

class Extraction extends Component {
  constructor(props) {
    super(props);
    this.file_chooser = React.createRef();
  }

  state = { ...initialState };

  componentDidMount() {
    this.validateSignedIn();
  }

  validateSignedIn = async () => {
    // let is_fresh_token = await getFreshToken()
    // if (!is_fresh_token) {
    //     this.props.history.replace("/sign_in");
    // }
    // else {

    // }
    if (localStorage.getItem("LOGEDIN") !== "true") {
      this.props.history.replace("/sign_in");
    }
  };

  openGallery = () => {
    this.file_chooser.current.click();
  };

  pickFile = (evt) => {
    this.handleReset();
    let files = evt.target.files;
    if (files.length > 0) {
      let file = evt.target.files[0];
      this.setData(file);
    }
  };

  handleFileName = (evt) => {
    this.handleReset();
    let file_name = evt.target.value;
    this.setState({ file_name: file_name });
    this.setData(file_name);
  };

  setData1 = async (file) => {
    this.startLoading();
    // Delay of 8 Seconds
    await this.timeout(8000);
    if (!sign_data.hasOwnProperty(file.name)) {
      this.stopLoading();
      alert("Can't extract data.");
      return;
    }
    let data = sign_data[file.name];
    this.setState({
      file: file,
      file_src: data["annot_file"] + "#toolbar=0", //URL.createObjectURL(file),
      doc_type: data["doc_type"],
      acc_no: data["acc_no"],
      extracted_sign: data["ext_sign"],
      auth_sign: data["auth_sign"],
      result: data["result"],
    });
    this.stopLoading();
  };

  setData = async (file_name) => {
    this.startLoading();
    // Delay of 2 Seconds
    await this.timeout(2000);
    let data = sign_data[file_name];
    this.setState({
      file_src: data["file"] + "#toolbar=0",
      doc_type: data["doc_type"],
      acc_no: data["acc_no"],
    });
    this.stopLoading();
  };

  extractSignExt = async () => {
    this.startLoading();
    // Delay of 3 Seconds
    await this.timeout(3000);
    let data = sign_data[this.state.file_name];
    this.setState({
      file_src: data["annot_file"] + "#toolbar=0",
      extracted_sign: data["ext_sign"],
    });
    this.stopLoading();
  };

  extractSignAuth = async () => {
    this.startLoading();
    // Delay of 2 Seconds
    await this.timeout(2000);
    let data = sign_data[this.state.file_name];
    this.setState({
      auth_sign: data["auth_sign"],
    });
    this.stopLoading();
  };

  getResult = async () => {
    this.startLoading();
    // Delay of 2 Seconds
    await this.timeout(2000);
    let data = sign_data[this.state.file_name];
    this.setState({
      result: data["result"],
      button_disabled: false,
    });
    this.stopLoading();
  };

  timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  startLoading = () => {
    this.setState({ open_progress_bar: true });
  };

  stopLoading = () => {
    this.setState({ open_progress_bar: false });
  };

  handleReset = () => {
    this.setState(initialState);
  };

  signOut = async () => {
    this.startLoading();
    // Delay of 2 Seconds
    await this.timeout(1000);
    localStorage.clear();
    this.props.history.replace("/sign_in");
    this.stopLoading();
  };

  render() {
    return (
      <div className="vh-100 scroll_hidden">
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col-md-6">
              <iframe
                className="w-100 border mt-1 scroll"
                style={{ height: "calc(100vh - 6.1rem)" }}
                src={this.state.file_src}
                key={this.state.file_src}
              />
            </div>
            <div className="col-md-6">
              {/* <div className='text-right pr-0'>
                                <input type='file' value='' ref={this.file_chooser} onChange={this.pickFile} accept=".pdf, image/*" hidden></input>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    onClick={() => this.openGallery()}
                                    className="bg_choose_btn pl-4 mt-3">
                                    <b>Choose File</b>
                                    <AttachFile className="ml-2 pt-1 pb-1" />
                                </Button>
                                <br />
                                <label className='border w-75'></label>
                            </div> */}
              <FormControl
                size="small"
                variant="outlined"
                className="w-100 mt-2"
              >
                <InputLabel id="document-label">Select Documents</InputLabel>
                <Select
                  labelId="document-label"
                  id="demo-simple-select"
                  value={this.state.file_name}
                  onChange={this.handleFileName}
                  label="Select Documents"
                >
                  {Object.keys(sign_data).map((k, i) => (
                    <MenuItem key={k} value={k}>
                      {k}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Paper className="w-100 mt-3">
                <TableContainer
                  className="border scroll"
                  style={{ height: "calc(100vh - 9.8rem)" }}
                >
                  <Table size="medium" hidden={this.state.hidden_table}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b>Document Type</b>
                        </TableCell>
                        <TableCell>{this.state.doc_type}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Account Number</b>
                        </TableCell>
                        <TableCell>{this.state.acc_no}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Extracted Signature</b>
                        </TableCell>
                        <TableCell size="small">
                          {this.state.acc_no === "---" ? (
                            <div>---</div>
                          ) : this.state.extracted_sign !== "" ? (
                            <img
                              src={this.state.extracted_sign}
                              style={{ height: "5rem" }}
                            />
                          ) : (
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={this.extractSignExt}
                              className=""
                            >
                              <b>Extract</b>
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Authenticated Signature</b>
                        </TableCell>
                        <TableCell size="small">
                          {this.state.acc_no === "---" ? (
                            <div>---</div>
                          ) : this.state.auth_sign !== "" ? (
                            <img
                              src={this.state.auth_sign}
                              style={{ height: "5rem" }}
                            />
                          ) : (
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={this.extractSignAuth}
                              className=""
                            >
                              <b>Extract</b>
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Result</b>
                        </TableCell>
                        <TableCell size="small">
                          {this.state.acc_no === "---" ||
                          this.state.extracted_sign === "" ? (
                            <div>---</div>
                          ) : this.state.result !== "" ? (
                            <div>
                              Extracted Signature
                              {this.state.result > 60 ? (
                                <b className="text-success">
                                  {" "}
                                  {this.state.result}%{" "}
                                </b>
                              ) : (
                                <b className="text-danger">
                                  {" "}
                                  {this.state.result}%{" "}
                                </b>
                              )}
                              match with Authenticated Signature.
                            </div>
                          ) : (
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={this.getResult}
                              className=""
                            >
                              <b>Get Result</b>
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {/* {
                                        this.state.result !== ''
                                            ? */}
                  <Table className="mt-3">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="center"
                          width="32%"
                          style={{ borderBottom: "none" }}
                        >
                          <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            disabled={this.state.button_disabled}
                            className="w-100"
                          >
                            <b>Process</b>
                          </Button>
                        </TableCell>
                        <TableCell
                          align="center"
                          width="32%"
                          style={{ borderBottom: "none" }}
                        >
                          <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            disabled={this.state.button_disabled}
                            className="w-100"
                          >
                            <b>Reject</b>
                          </Button>
                        </TableCell>
                        <TableCell
                          align="center"
                          width="36%"
                          style={{ borderBottom: "none" }}
                        >
                          <Button
                            variant="contained"
                            size="medium"
                            disabled={this.state.button_disabled}
                            className="w-100"
                          >
                            <b>Further Review</b>
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {/* :
                                            <div />
                                    } */}
                </TableContainer>
              </Paper>
            </div>
          </div>
        </div>
        <Backdrop
          className={this.props.classes.backdrop}
          open={this.state.open_progress_bar}
        >
          <CircularProgress size={80} color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

export default withStyles(useStyles)(Extraction);
