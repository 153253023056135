import React, { Component } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import {
  Email,
  LockOpen,
  Person,
  VpnKey,
  Visibility,
  VisibilityOff,
  PersonAdd,
} from "@mui/icons-material";
import { isEmpty } from "../custom_libraries/validation";
import { get, post } from "../custom_libraries/serverRequests";
import urls from "../custom_libraries/urls";
import gv from "../custom_libraries/globalVariables";

const client_logo =
  "logo192.png";

class SignUp extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    ip_address: localStorage.getItem(gv.IP_ADDRESS) || "",
    email: "",
    username: "",
    password: "",
    confirm_password: "",
    is_show_password: false,

    spinner_hidden: true,
    button_disabled: false,
  };

  componentDidMount() {
    // let refresh_token = localStorage.getItem(gv.REFRESH_TOKEN)
    // if(refresh_token !== null){
    //     this.props.history.replace("/");
    // }
  }

  // componentWillUnmount() {
  //     // fix Warning: Can't perform a React state update on an unmounted component
  //     this.setState = () => {
  //         return;
  //     };
  // }

  handleEmail = (evt) => {
    this.setState({ email: evt.target.value });
  };

  handleUsername = (evt) => {
    this.setState({ username: evt.target.value });
  };

  handlePassword = (evt) => {
    this.setState({ password: evt.target.value });
  };

  handleConfirmPassword = (evt) => {
    this.setState({ confirm_password: evt.target.value });
  };

  isEmptyFields = () => {
    let data = [this.state.email, this.state.username, this.state.password];

    return isEmpty(data);
  };

  formData = () => {
    // let form = document.querySelector('form');
    let fd = new FormData();

    fd.append("email", this.state.email.trim());
    fd.append("username", this.state.username.trim());
    fd.append("password", this.state.password.trim());

    return fd;
  };

  signupWithData = async () => {
    if (this.isEmptyFields()) {
      alert("Fill All Fields !!!");
      return;
    }

    // if(this.state.password !== this.state.confirm_password){
    //     alert("Password Mismatched !!!")
    //     this.setState({confirm_password : ''})
    //     return
    // }

    this.setState({ button_disabled: true });
    this.setState({ spinner_hidden: false });

    let headers = {};

    let post_data = this.formData();

    let data = await post(urls.SIGNUP, headers, post_data);

    if (data !== false) {
      // console.log(data)
      if (data.hasOwnProperty("success")) {
        if (data["success"] === true) {
          alert(data["message"]);
          this.props.history.replace("/login");
        } else {
          alert(data["message"]);
        }
      }
    } else {
      alert("Something went wrong. Please try again !!!");
    }

    this.setState({ button_disabled: false });
    this.setState({ spinner_hidden: true });
  };

  keyPress = (evt) => {
    if (evt.key === "Enter") {
      this.signupWithData();
    }
  };

  render() {
    return (
      <div
        className="d-flex justify-content-center align-items-center h-100"
        style={{ minHeight: "100vh", backgroundColor: "#F0F4F3" }}
      >
        <div className="">
          <Card>
            <div className="row">
              <div
                className="col-sm-5 text-center d-flex justify-content-center align-items-center pe-0"
                style={{ backgroundColor: "#3AB397" }}
              >
                <div className="pt-5 pb-5 ps-4 pe-4">
                  <div className="welcome_text">Welcome Back!</div>
                  <div className="text-white">
                    To keep connected with us please
                    <br />
                    login with your personal info
                  </div>
                  <div className="pt-4">
                    <Button
                      variant="outlined"
                      size="large"
                      color="inherit"
                      className="text-white"
                      style={{ borderRadius: "25px" }}
                      onClick={() => this.props.history.replace("/sign_in")}
                      disabled={this.state.button_disabled}
                    >
                      <LockOpen className="me-2" />
                      <b>Sign In</b>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-7 ps-0">
                <CardContent className="p-0">
                  <div className="text-center">
                    <img src={client_logo} style={{ width: "75%" }}></img>
                  </div>
                  <div className="p-2">
                    <div className="pt-1 text-center">
                      <TextField
                        label="Name"
                        size="small"
                        variant="outlined"
                        className="w-75"
                        value={this.state.username}
                        onChange={this.handleUsername}
                        onKeyPress={this.keyPress}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person color="action" className="me-2" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="pt-3 text-center">
                      <TextField
                        label="Email"
                        size="small"
                        variant="outlined"
                        className="w-75"
                        value={this.state.email}
                        onChange={this.handleEmail}
                        onKeyPress={this.keyPress}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email color="action" className="me-2" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>

                    <div className="pt-3 text-center">
                      <TextField
                        label="Password"
                        type={this.state.is_show_password ? "text" : "password"}
                        variant="outlined"
                        size="small"
                        className="w-75"
                        value={this.state.password}
                        onChange={this.handlePassword}
                        onKeyPress={this.keyPress}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <VpnKey color="action" className="me-2" />
                            </InputAdornment>
                          ),

                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  this.setState({
                                    is_show_password:
                                      !this.state.is_show_password,
                                  })
                                }
                              >
                                {this.state.is_show_password ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>

                    <div className="pt-3 pb-3 text-center">
                      <Button
                        variant="contained"
                        size="large"
                        className="login_button"
                        onClick={this.signupWithData}
                        disabled={this.state.button_disabled}
                      >
                        <CircularProgress
                          className="text-white me-2"
                          size={24}
                          hidden={this.state.spinner_hidden}
                        />
                        <PersonAdd className="me-2" />
                        <b>SIGN UP</b>
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default SignUp;
