import React from "react";
import PropTypes from "prop-types";
// import { IMaskInput } from 'react-imask';
// import NumberFormat from 'react-number-format';
import firebase_errors from "../jsons/firebase_errors.json";
import { signOut } from "./auth";

export function isEmpty(data) {
  let trim_data = data.map((s) => s.toString().trim());
  // data.map(function(s:any) { return s.trim() });
  if (trim_data.includes("")) {
    return true;
  } else {
    return false;
  }
}

export async function sleep(delay) {
  return new Promise((res) => setTimeout(res, delay));
}

export async function firebaseErrors(error) {
  let e = error.code;
  if (e === "auth/requires-recent-login") {
    alert(
      "This operation requires recently login. So please do this process immediately after Sign In or OTP verification"
    );
    await sleep(3000);
    signOut();
  }
  let fe = firebase_errors;
  alert(fe[e] !== undefined ? fe[e] : e);
}

export function percentToPixel(percent, total_px) {
  let px = (parseInt(total_px) / 100) * parseInt(percent);
  return px;
}

export function dateToString(d, format = "yyyy-mm-dd") {
  let datetime = d.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  let date = datetime.split(",")[0];
  let time = datetime.split(",")[1].trim();

  let dd = date.split("/")[0];
  let mm = date.split("/")[1];
  let yyyy = date.split("/")[2];
  let HH = time.split(":")[0];
  let MM = time.split(":")[1];

  let MMM = d.toLocaleDateString("en-GB", { month: "short" });
  let MMMM = d.toLocaleDateString("en-GB", { month: "long" });
  let format_dict = {
    "yyyy-mm-dd": `${yyyy}-${mm}-${dd}`,
    "yyyy-mm-dd HH:MM": `${yyyy}-${mm}-${dd} ${HH}:${MM}`,
    "dd MMMM, yyyy": `${dd} ${MMMM}, ${yyyy}`,
    "dd MMMM yyyy, HH:MM": `${dd} ${MMMM} ${yyyy}, ${HH}:${MM}`,
  };
  return format_dict[format];
}

export function currencyOf(number) {
  let currency = parseFloat(number)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return currency;
}

// export const MobileNumberFormat = React.forwardRef(function MobileNumberFormat(props, ref) {
//     const { onChange, ...other } = props;
//     return (
//         <IMaskInput
//             {...other}
//             mask="+27 00 000 0000"
//             definitions={{
//                 '#': /[1-9]/,
//             }}
//             inputRef={ref}
//             onAccept={(value) => onChange({ target: { name: props.name, value } })}
//             overwrite
//         />
//     );
// });

// export const CurrencyFormat = React.forwardRef(function CurrencyFormat(props, ref) {
//     const { onChange, ...other } = props;

//     return (
//         <NumberFormat
//             {...other}
//             getInputRef={ref}
//             onValueChange={(values) => {
//                 onChange({
//                     target: {
//                         name: props.name,
//                         value: values.value,
//                     },
//                 });
//             }}
//             thousandSeparator
//             // isNumericString
//             decimalScale={2}
//             prefix="R "
//         />
//     );
// });
