import { Component } from "react";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
} from "@mui/material";
import {
  Email,
  LockOpen,
  VpnKey,
  Visibility,
  VisibilityOff,
  Group,
} from "@mui/icons-material";
import firebaseConfig from "../custom_libraries/firebaseConfig";
import { firebaseErrors, isEmpty } from "../custom_libraries/validation";
import { isAuthenticated } from "../custom_libraries/auth";

const client_logo =
  "logo192.png";

class SignIn extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    email: "",
    password: "",
    is_show_password: false,
    open_progress_bar: false,
  };

  componentDidMount() {
    this.validateUser();
  }

  // componentWillUnmount() {
  //     // fix Warning: Can't perform a React state update on an unmounted component
  //     this.setState = (state, callback) => {
  //         return;
  //     };
  // }

  validateUser = async () => {
    if (await isAuthenticated()) {
      return;
    }
  };

  isEmptyFields = () => {
    let data = [this.state.email, this.state.password];
    return isEmpty(data);
  };

  signIn = async () => {
    if (this.isEmptyFields()) {
      alert("User Type, Email & Password should not be empty.");
      return;
    }
    if (!window.navigator.onLine) {
      alert("Check your internet connection and try again");
      return;
    }
    this.startLoading();
    try {
      const res = await firebaseConfig
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password);
      if (res.operationType === "signIn") {
        window.location.replace("/");
      }
    } catch (error) {
      firebaseErrors(error);
    }
    this.stopLoading();
  };

  keyPress = (evt) => {
    if (evt.key === "Enter") {
      this.signIn();
    }
  };

  startLoading = () => {
    this.setState({ open_progress_bar: true });
  };

  stopLoading = () => {
    this.setState({ open_progress_bar: false });
  };

  render() {
    return (
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <Card className="text-center p-5 border" style={{ width: "50%" }}>
          <CardContent>
            <img src={client_logo} className="w-100 mb-1" />
            <TextField
              label="Email"
              variant="outlined"
              className="w-100 mt-5"
              size="small"
              autoComplete="username"
              value={this.state.email}
              onChange={(evt) => this.setState({ email: evt.target.value })}
              onKeyPress={this.keyPress}
              InputProps={{
                className: "txt_font",
                startAdornment: (
                  <InputAdornment position="start">
                    <Email color="action" className="me-2" />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                className: "txt_font",
              }}
            />
            <TextField
              label="Password"
              type={this.state.is_show_password ? "text" : "password"}
              variant="outlined"
              className="w-100 mt-4"
              size="small"
              value={this.state.password}
              onChange={(evt) => this.setState({ password: evt.target.value })}
              onKeyPress={this.keyPress}
              InputProps={{
                className: "txt_font",
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey color="action" className="me-2" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        this.setState({
                          is_show_password: !this.state.is_show_password,
                        })
                      }
                    >
                      {this.state.is_show_password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                className: "txt_font",
              }}
            />
            <Button
              size="large"
              variant="contained"
              className="w-100 mt-5"
              style={{ borderRadius: "10px" }}
              onClick={this.signIn}
            >
              <LockOpen className="me-2" />
              <b>SIGN IN</b>
            </Button>
          </CardContent>
        </Card>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.open_progress_bar}
        >
          <CircularProgress size={80} color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

export default SignIn;
