export const useStyles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  dataGrid: {
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-sortIcon": {
      color: "white",
      margin: 5,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    },
  },
  newsTextField: {
    minHeight: "calc(100vh - 11.8rem)",
    maxHeight: "calc(100vh - 11.8rem)",
  },
});
