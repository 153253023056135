export async function get(url, headers = {}) {
  try {
    const response = await fetch(url, {
      headers: headers,
      method: "GET",
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // handle error
    console.log(error);
    // alert('Something went wrong. Please try again !!!')
    return false;
  }
}

export async function post(url, headers = {}, post_data = {}) {
  try {
    const response = await fetch(url, {
      headers: headers,
      method: "POST",
      body: post_data,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    // handle error
    console.log(error);
    // alert('Something went wrong. Please try again !!!')
    return false;
  }
}
