export const useStyles = (theme) => ({
  dataGrid: {
    "& .super-app-theme--header": {
      backgroundColor: "rgba(255, 7, 0, 0.55)",
    },
    "& .MuiDataGrid-sortIcon": {
      color: "white",
      margin: 5,
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    },
    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
      {
        outline: "none",
      },
    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    // MUI 4
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
  newsTextField: {
    minHeight: "calc(100vh - 16rem)",
    maxHeight: "calc(100vh - 16rem)",
  },
  summaryTextField: {
    minHeight: "calc(100vh - 10.5rem)",
    maxHeight: "calc(100vh - 10.5rem)",
  },
});
