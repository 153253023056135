export default class gv {
  static AUTH_TOKEN = "auth_token";
  static REFRESH_TOKEN = "refresh_token";
  static TOKEN_EXPIRY = "token_expiry";

  static USER_TYPE = "user_type";
  static USER_NAME = "user_name";
  static USER_EMAIL = "user_email";

  static USER_TYPE_MAKER = "maker";
  static USER_TYPE_VALIDATOR = "validator";
  static USER_TYPE_ADMIN = "admin";

  static USER_STATE_ACTIVE = 1;
  static USER_STATE_INACTIVE = 0;

  static USER_ACCESS_ALLOW = 1;
  static USER_ACCESS_DENY = 0;

  static STATUS_SUBMIT = "submit";
  static STATUS_ACCEPT = "accept";
  static STATUS_REJECT = "reject";
  static STATUS_DISCARD = "discard";

  static EXTRA_FIELDS = [
    "file_name",
    "id",
    "date_time",
    "status",
    "submit_time",
    "sr_no",
    "reject_reason",
    "reject_time",
    "accept_time",
    "discard_time",
  ];
}
